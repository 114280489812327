<template>
    <div class="relative gm-padding-y">

        <div class="container py-12 text-center">
            <h3 class="!leading-[1.5] font-bold">
                Instantly connect Greetmate with
                <span class="text-callout-primary">100+ Apps and Services</span>
                through Zapier and Webhooks.
            </h3>


            <div class="flex gap-4 lg:gap-8 flex-wrap justify-center items-center mt-8">
                <p v-for="integration in integrations" :key="integration.name"
                   class="flex items-center gap-4 bg-gray-50 p-2 rounded-lg">
                    <img :src="integration.logo" alt="logo" class="h-6 lg:h-8 rounded-lg"/>
                    <span class="lg:text-lg">{{ integration.name }}</span>
                </p>

            </div>

        </div>
    </div>
</template>
<script setup lang="ts">
import mailchimp from '@/../images/logos/mailchimp.jpg?format=webp&w=500'
import asana from '@/../images/logos/asana.svg'
import slack from '@/../images/logos/slack.png?format=webp&w=500'
import zapier from '@/../images/logos/zapier.svg'
import hubspot from '@/../images/logos/hubspot.svg'
import trello from '@/../images/logos/trello.svg'
import gmail from '@/../images/logos/gmail.svg'
import googlecalendar from '@/../images/logos/googlecalendar.svg'
import googlesheets from '@/../images/logos/googlesheets.svg'
import airtable from '@/../images/logos/airtable.svg'

const integrations = [
    {
        logo: mailchimp,
    },
    {
        logo: asana,
    },
    {
        logo: slack,
    },
    {
        logo: zapier,
    },
    {
        logo: hubspot,
    },
    {
        logo: trello,
    },
    {
        logo: gmail,
        name: 'Google Mail'
    },
    {
        logo: googlecalendar,
        name: 'Google Calendar'
    },
    {
        logo: googlesheets,
        name: 'Google Sheets'
    },
    {
        logo: airtable,
    }
]
</script>
